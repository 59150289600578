/* Nom et titre */
.nameStyle {
  font-size: 3em; /* Taille de la police par défaut */
  font-weight: bold; /* Ajouter du poids au texte pour plus de présence */
  color: #fff; /* Couleur de texte blanche */
  margin-bottom: 0.5rem; /* Réduire légèrement la marge inférieure */
}

/* Éléments en ligne */
.inlineChild {
  display: inline-block;
}

/* Style de la machine à écrire */
.Typewriter__wrapper {
  font-size: 1.4em; /* Taille de la police par défaut */
  font-weight: bold; /* Ajouter du poids au texte pour plus de présence */
}

/* Curseur de la machine à écrire */
.Typewriter__cursor {
  font-size: 1.4em; /* Taille de la police par défaut */
  font-weight: bold; /* Ajouter du poids au curseur pour plus de présence */
}

/* Titre */
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  font-size: 1em; /* Taille de la police par défaut */
  color: #fff;
  line-height: 1.5;
  margin-top: 1rem;
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #000;
  overflow: hidden;
  z-index: -1;
}

.background span {
  width: 50vmin;
  height: 50vmin;
  border-radius: 50vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 25;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.background span:nth-child(0) {
  color: #FFACAC;
  top: 19%;
  left: 34%;
  animation-duration: 13s;
  animation-delay: -235s;
  transform-origin: 14vw -12vh;
  box-shadow: 100vmin 0 13.325164954611258vmin currentColor;
}
.background span:nth-child(1) {
  color: #FFACAC;
  top: 80%;
  left: 77%;
  animation-duration: 31s;
  animation-delay: -116s;
  transform-origin: -6vw -16vh;
  box-shadow: 100vmin 0 12.636917750263517vmin currentColor;
}
.background span:nth-child(2) {
  color: #60cee1;
  top: 37%;
  left: 63%;
  animation-duration: 23s;
  animation-delay: -119s;
  transform-origin: -15vw -6vh;
  box-shadow: -100vmin 0 12.543659743165675vmin currentColor;
}
.background span:nth-child(3) {
  color: #E45A84;
  top: 78%;
  left: 16%;
  animation-duration: 10s;
  animation-delay: -99s;
  transform-origin: 4vw -20vh;
  box-shadow: 100vmin 0 13.484298758622467vmin currentColor;
}
.background span:nth-child(4) {
  color: #60cee1;
  top: 27%;
  left: 9%;
  animation-duration: 188s;
  animation-delay: -14s;
  transform-origin: -19vw 14vh;
  box-shadow: -100vmin 0 13.486264364644878vmin currentColor;
}
.background span:nth-child(5) {
  color: #52e0a3;
  top: 24%;
  left: 51%;
  animation-duration: 142s;
  animation-delay: -164s;
  transform-origin: -2vw -2vh;
  box-shadow: 100vmin 0 12.663687607530115vmin currentColor;
}
.background span:nth-child(6) {
  color: #d75585;
  top: 54%;
  left: 1%;
  animation-duration: 239s;
  animation-delay: -95s;
  transform-origin: 4vw -8vh;
  box-shadow: -100vmin 0 13.086159507124453vmin currentColor;
}
.background span:nth-child(7) {
  color: #d75585;
  top: 90%;
  left: 29%;
  animation-duration: 142s;
  animation-delay: -248s;
  transform-origin: -15vw 15vh;
  box-shadow: 100vmin 0 12.944522447106852vmin currentColor;
}
.background span:nth-child(8) {
  color: #3bb727;
  top: 43%;
  left: 73%;
  animation-duration: 175s;
  animation-delay: -183s;
  transform-origin: -22vw 4vh;
  box-shadow: -100vmin 0 13.224082844222147vmin currentColor;
}
.background span:nth-child(9) {
  color: #2e1dec;
  top: 26%;
  left: 6%;
  animation-duration: 29s;
  animation-delay: -174s;
  transform-origin: -20vw 22vh;
  box-shadow: -100vmin 0 12.742151839237701vmin currentColor;
}
.background span:nth-child(10) {
  color: #60cee1;
  top: 82%;
  left: 40%;
  animation-duration: 35s;
  animation-delay: -146s;
  transform-origin: 22vw 20vh;
  box-shadow: -100vmin 0 12.625767372122596vmin currentColor;
}
.background span:nth-child(11) {
  color: #583C87;
  top: 70%;
  left: 97%;
  animation-duration: 18s;
  animation-delay: -75s;
  transform-origin: -20vw -10vh;
  box-shadow: -100vmin 0 13.460277755601481vmin currentColor;
}

/* Media Queries pour les smartphones */
@media (max-width: 768px) {
  .nameStyle {
    font-size: 2.5em; /* Réduire la taille du texte pour les petits écrans */
  }

  .Typewriter__wrapper {
    font-size: 1.2em; /* Réduire la taille de la machine à écrire */
  }

  .Typewriter__cursor {
    font-size: 1.2em; /* Réduire la taille du curseur */
  }

  .description {
    font-size: 0.9em; /* Réduire la taille de la description */
    margin-top: 0.5rem; /* Réduire la marge supérieure */
    text-align: center;
  }

  .background span {
    width: 30vmin; /* Réduire la taille des éléments d'arrière-plan */
    height: 30vmin; /* Réduire la taille des éléments d'arrière-plan */
    border-radius: 30vmin; /* Réduire le rayon des bordures */
  }
}

/* Media Queries pour les grands écrans */
@media (min-width: 1200px) {
  .nameStyle {
    font-size: 4em; /* Augmenter la taille du texte pour les grands écrans */
  }

  .Typewriter__wrapper {
    font-size: 1.6em; /* Augmenter la taille de la machine à écrire */
  }

  .Typewriter__cursor {
    font-size: 1.6em; /* Augmenter la taille du curseur */
  }

  .description {
    font-size: 1.2em; /* Augmenter la taille de la description */
    margin-top: 1.5rem; /* Augmenter la marge supérieure */
    text-align: center;
  }

  .background span {
    width: 60vmin; /* Augmenter la taille des éléments d'arrière-plan */
    height: 60vmin; /* Augmenter la taille des éléments d'arrière-plan */
    border-radius: 60vmin; /* Augmenter le rayon des bordures */
  }
}
