.vertical-timeline-element--work .vertical-timeline-element-content {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.vertical-timeline-element--work .vertical-timeline-element-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.vertical-timeline-element-date {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: #7B8A8B; /* Default color */
}

.vertical-timeline-element-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.vertical-timeline-element-subtitle {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 0.5rem;
}

.vertical-timeline-element-content p {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: #62f8ff; /* Default color */
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px rgba(124, 123, 123, 0.5);
}

.vertical-timeline-element--work .vertical-timeline-element-content-arrow {
    border-right: 7px solid transparent;
}

.icon-color-default {
    background: #2D4059;
    color: #fff;
}

.icon-color-laravel {
    background: #E84545;
    color: #fff;
}

.icon-color-symfony {
    background: #4058F2;
    color: #fff;
}

.icon-color-php {
    background: #66CC66;
    color: #fff;
}
.timeline-link {
    color: inherit; /* Inherit the color from the parent element */
    text-decoration: none;
}

.timeline-link:hover {
    text-decoration: underline;
}

@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #000;
    overflow: hidden;
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.1);
    animation: animate 17s linear infinite;
}

.background li:nth-child(0) {
    left: 45%;
    width: 362px;
    height: 362px;
    bottom: -362px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 3%;
    width: 374px;
    height: 374px;
    bottom: -374px;
    animation-delay: 1s;
}
.background li:nth-child(2) {
    left: 29%;
    width: 286px;
    height: 286px;
    bottom: -286px;
    animation-delay: 9s;
}
.background li:nth-child(3) {
    left: 75%;
    width: 353px;
    height: 353px;
    bottom: -353px;
    animation-delay: 6s;
}
.background li:nth-child(4) {
    left: 11%;
    width: 344px;
    height: 344px;
    bottom: -344px;
    animation-delay: 13s;
}
.background li:nth-child(5) {
    left: 70%;
    width: 308px;
    height: 308px;
    bottom: -308px;
    animation-delay: 10s;
}
.background li:nth-child(6) {
    left: 82%;
    width: 304px;
    height: 304px;
    bottom: -304px;
    animation-delay: 21s;
}
.background li:nth-child(7) {
    left: 19%;
    width: 302px;
    height: 302px;
    bottom: -302px;
    animation-delay: 21s;
}
.background li:nth-child(8) {
    left: 49%;
    width: 348px;
    height: 348px;
    bottom: -348px;
    animation-delay: 20s;
}
.background li:nth-child(9) {
    left: 78%;
    width: 427px;
    height: 427px;
    bottom: -427px;
    animation-delay: 43s;
}