.mainContainer {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.competenceContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.section-container {
    margin-bottom: 40px;
}

.section-title {
    text-align: center;
}

.card-container {
    display: flex;
    justify-content: space-around; /* Espacement entre les cartes */
    flex-wrap: wrap; /* Permet de passer à la ligne suivante si nécessaire */
    padding: 20px;
    border-radius: 10px;
}

.card-wrapper {
    flex: 1 1 calc(33.333% - 20px); /* Pour les écrans larges */
    margin: 10px;
}

@media (max-width: 992px) {
    .card-wrapper {
        flex: 1 1 calc(50% - 20px); /* Pour les tablettes */
    }
}

@media (max-width: 576px) {
    .card-wrapper {
        flex: 1 1 calc(100% - 20px); /* Pour les smartphones */
    }
}

.competence-card {
    color: #fff; /* Texte blanc */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.competence-card .ant-card-head-title {
    display: flex;
    align-items: center;
}

.competence-card .ant-card-head-title .fa-icon {
    margin-right: 10px;
    color: #fff; /* Couleur de l'icône blanche */
}
