/* src/components/Projets/Projets.css */

.projets-container {
  width: 90%;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-container {
  border-radius: 10px;
  margin: 2vh 0 !important;
}

.link-projets {
  text-decoration: none;
  color: #444;
  transition: color 200ms ease-in-out;
}

.link-projets:hover {
  color: #777;
  text-decoration: underline;
}

.section-title {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.card-container {
  margin-bottom: 30px;
}

.project-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  color: #fff;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.tags-container {
  margin-bottom: 10px;
}

.project-description {
  font-size: 0.9em;
  text-align: justify !important;
  color: #fff;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.card-icon {
  transition: transform 0.3s;
  color: #fff;
}

.card-icon:hover {
  transform: scale(1.2);
  color: #ff6347;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .section-title {
    font-size: 1.8em;
  }
}

@media (max-width: 992px) {
  .section-title {
    font-size: 1.6em;
  }
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.4em;
  }

  .project-card {
    margin-bottom: 20px;
  }

  .project-description {
    font-size: 0.85em;
  }
}

@media (max-width: 576px) {
  .section-title {
    font-size: 1.2em;
  }

  .project-card {
    margin-bottom: 15px;
  }

  .project-description {
    font-size: 0.8em;
  }
}
