.App{
	margin: 0;
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
}
.mainContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
body {
    overflow-x: hidden;
}