.apropos-container {
    padding: 40px;
}
.aboutMe {
    font-size: 19px;
    text-align: justify;
}

.background-propos {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #000;
    overflow: hidden;
    z-index: -1;
}

.background-propos span {
    width: 50vmin;
    height: 50vmin;
    border-radius: 50vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 25;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.background-propos span:nth-child(1) {
    color: #efefef;
    top: 70%;
    left: 29%;
    animation-duration: 170s;
    animation-delay: -40s;
    transform-origin: -15vw 15vh;
    box-shadow: 100vmin 0 12.944522447106852vmin currentColor;
}
.background-propos span:nth-child(2) {
    color: #3bb727;
    top: 43%;
    left: 73%;
    animation-duration: 30s;
    animation-delay: -30s;
    transform-origin: -22vw 4vh;
    box-shadow: -100vmin 0 13.224082844222147vmin currentColor;
}
.background-propos span:nth-child(3) {
    color: #2e1dec;
    top: 26%;
    left: 6%;
    animation-duration: 19s;
    animation-delay: -19s;
    transform-origin: -20vw 22vh;
    box-shadow: -100vmin 0 12.742151839237701vmin currentColor;
}

/* Media Queries pour les appareils de tailles différentes */
@media (max-width: 1200px) {
    .apropos-container {
        padding: 20px;
        width: 90%;
        margin: 0 auto;
    }
    .aboutMe {
        font-size: 17px;
    }
}

@media (max-width: 992px) {
    .apropos-container {
        padding: 15px;
        width: 90%;
        margin: 0 auto;
    }
    .aboutMe {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .apropos-container {
        padding: 10px;
        width: 90%;
        margin: 0 auto;
    }
    .aboutMe {
        font-size: 15px;
    }
}

@media (max-width: 576px) {
    .apropos-container {
        padding: 5px;
        width: 90%;
        margin: 0 auto;
    }
    .aboutMe {
        font-size: 14px;
    }
}

.cv-link {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cv-link:hover {
    background-color: #0056b3;
}
