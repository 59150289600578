.header {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 1vh 0;
}

.navbar-collapse {
  justify-content: center;
}

.header .nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  margin: 0 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-size: 1.1em;
  padding: 0.5vh 1vw;
  border-radius: 5px;
  white-space: nowrap; /* Empêche le texte de se briser en plusieurs lignes */
}

.header a svg {
  width: 20px; /* Taille des icônes */
  height: 20px;
  margin-right: 8px; /* Espacement entre l'icône et le texte */
}

.active a {
  background-color: #007bff !important; /* Couleur bleue pour le lien actif */
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.38);
}

.header .header-navbar-link a:hover {
  background-color: #0056b3; /* Couleur bleue plus foncée pour le survol */
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.38);
}

/* Responsive styles */
@media (max-width: 576px) {
  .header .nav {
    flex-direction: column;
  }

  .header a {
    margin: 5px 0;
  }
}

.header .brand {
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}
